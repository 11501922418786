export const StudentData = [
    // {
    //     "id": "S. No.",
    //     admnNo: "Admn.\nNo.",
    //     studentName: "Student Name",
    //     className: "Class Name",
    //     gender: "Gender",
    //     fatherName: "Father's Name",
    //     address: "Address",
    // },
    {
        id: 1,
        admnNo: 1106,
        studentName: "RAHUL ARYA",
        className: "B.ED. II YEAR",
        gender: "MALE",
        fatherName: "JEEWA NAND ARYA",
        address: "TALLA TIRCHHAKHET, ,BHOWALI, UTTARAKHAND- INDIA",
    },
    {
        id: 2,
        admnNo: 1107,
        studentName: "NIHARIKA RANA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "KULDEEP SINGH RANA",
        address: "KARGHATIYA, SITRARGANJ ,SITARGANJ,\nUTTARAKHAND-262405 INDIA",
    },
    {
        id: 3,
        admnNo: 1109,
        studentName: "RIYA SIRELIYA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "MOHAN RAM ARYA",
        address:
            "MALLA PACHONIYA LAKHAN MANDAL , CHORGALIYA\n,HALDWANI, UTTARAKHAND-263139 INDIA",
    },
    {
        id: 4,
        admnNo: 1110,
        studentName: "JYOTI BHANDARI",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "KULBIR SINGH",
        address:
            "VILL KUNDESHWARI, PO KUNDESHWARI ,KASHIPUR, UTTARAKHAND-244713 INDIA",
    },
    {
        id: 5,
        admnNo: 1111,
        studentName: "RIYA PALARIYA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "HARISH CHANDRA PALARIYA",
        address: "CHANOTI NAUKUCHIYATAL, ,BHIMTAL, UTTARAKHAND-263136 INDIA",
    },
    {
        id: 6,
        admnNo: 1112,
        studentName: "MEDHA SINGH",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "DEVENDRA KUMAR\nSINGH",
        address:
            "MASI BAZAR P.O. MASI, BLOCK CHAUKHUTIA\n,ALMORA, UTTARAKHAND-263658 INDIA",
    },
    {
        id: 7,
        admnNo: 1113,
        studentName: "SHIWANI BISHT",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "RAM SINGH BISHT",
        address:
            "BRIJ VIHAR CHOOI , RAMNAGAR ,NAINITAL,\nUTTARAKHAND-244715 INDIA",
    },
    {
        id: 8,
        admnNo: 1114,
        studentName: "HEMLATA RATHOUR",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "NANDAN SINGH RATHOUR",
        address: "BLOCK ROAD, BHIMTAL ,BHIMTAL, UTTARAKHAND- 263136 INDIA",
    },
    {
        id: 9,
        admnNo: 1115,
        studentName: "HEMA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "PURAN RAM",
        address: "VILL. MAJHERA GARAMPANI, ,NAINITAL, UTTARAKHAND-163135 INDIA",
    },
    {
        id: 10,
        admnNo: 1116,
        studentName: "JYOTI MEHRA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "HARIHAR SINGH MEHRA",
        address:
            "VILL. PUNIYABAGAR PO. KHERA, ALMORA ,ALMORA, UTTARAKHAND-263601 INDIA",
    },
    {
        id: 11,
        admnNo: 1117,
        studentName: "BHAGWAT SINGH",
        className: "B.ED. II YEAR",
        gender: "MALE",
        fatherName: "D S MEHRA",
        address:
            "PIPAL POKHRA NO 2 FATEHPUR , ,HALDWANI,\nUTTARAKHAND-263139 INDIA",
    },
    {
        id: 12,
        admnNo: 1118,
        studentName: "PUNYA PRASOON YADAV",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "PIYUSH RANJAN YADAV",
        address:
            "OPP. ISLAMIA INTER COLLEGE BUDAUN , ,BAREILLY, UTTRA PRADESH- INDIA",
    },
    {
        id: 13,
        admnNo: 1119,
        studentName: "SHUBHAM SHAHI",
        className: "B.ED. II YEAR",
        gender: "MALE",
        fatherName: "BHUPENDAR KUMAR SHAHI",
        address:
            "SHANTIPURI NO 2, U.S. NAGAR ,SITARGANJ, UTTARAKHAND-263148 INDIA",
    },
    {
        id: 14,
        admnNo: 1120,
        studentName: "JYOTI SHUKLA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "LATE. JWALA PRASAD SHUKLA",
        address: "HAIRIYAGAUN BHIMTAL, ,BHIMTAL, UTTARAKHAND- 263136 INDIA",
    },
    {
        id: 15,
        admnNo: 1121,
        studentName: "PRATIBHA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "RAMESH SINGH",
        address:
            "PIPALIYA PISTAUR, PO NANAKMATTA ,RUDRAPUR,\nUTTARAKHAND-262311 INDIA",
    },
    {
        id: 16,
        admnNo: 1122,
        studentName: "VIPUL PANDEY",
        className: "B.ED. II YEAR",
        gender: "MALE",
        fatherName: "GANESH DATT PANDEY",
        address: "GARUR BAGESHWAR, ,BAGESHWAR, UTTARAKHAND- 263641 INDIA",
    },
    {
        id: 17,
        admnNo: 1123,
        studentName: "KAVITA BISHT",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "MAHESH SINGH BISHT",
        address:
            "NEAR BSNL TOWER CHILIYANAULA , ALMORA\n,RANIKHET, UTTARAKHAND-263645 INDIA",
    },
    {
        id: 18,
        admnNo: 1124,
        studentName: "MANISHA JOSHI",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "GOPAL DUTT JOSHI",
        address:
            "VILLAGE GURNA P.O. DAULAGHAT, ,ALMORA, UTTARAKHAND-263655 INDIA",
    },
    {
        id: 19,
        admnNo: 1125,
        studentName: "MANJU GOSWAMI",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "JAGDISH GIRI GOSWAMI",
        address: "SIMKHOLA RANMAN ALMORA , ,ALMORA, UTTARAKHAND-263638 INDIA",
    },
    {
        id: 20,
        admnNo: 1126,
        studentName: "EKTA ARYA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "MAHENDRA ARYA",
        address: "VILL. BIMOLA PO. GARUR, ,BAGESHWAR, UTTARAKHAND-263641 INDIA",
    },
    {
        id: 21,
        admnNo: 1127,
        studentName: "PAN SINGH BISHT",
        className: "B.ED. II YEAR",
        gender: "MALE",
        fatherName: "SHER SINGH BISHT",
        address:
            "VILLAGE SUPI BLOCK RAMGARH , NAINITAL\n,NAINITAL, UTTARAKHAND-263132 INDIA",
    },
    {
        id: 22,
        admnNo: 1128,
        studentName: "MANJU",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "KESHAV RAM",
        address:
            "ST. JOSEPH'S COLLEGE, NAINITAL ,NAINITAL, UTTARAKHAND-263002 INDIA",
    },
    {
        id: 23,
        admnNo: 1129,
        studentName: "PRIYA POKHARIYA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "BHAGWAT SINGH POKHARIYA",
        address: "VISHNUVATIKA, PANDEY GAUN ,BHIMTAL, UTTARAKHAND-263136 INDIA",
    },
    {
        id: 24,
        admnNo: 1130,
        studentName: "SHOBHA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "SOHAN LAL",
        address:
            "ROYAL HOTEL COMPOUND MALLITAL , ,NAINITAL, UTTARAKHAND-263001 INDIA",
    },
    {
        id: 25,
        admnNo: 1131,
        studentName: "GEETA RAIKWAL",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "PAN SINGH RAIKWAL",
        address: "REHAR BHOWALI, ,BHOWALI, UTTARAKHAND-263132\nINDIA",
    },
    {
        id: 26,
        admnNo: 1132,
        studentName: "DIKSHA BOHRA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "RAM SINGH BOHRA",
        address:
            "KAHALQUEERA RAMGARH ROAD , ,BHOWALI,\nUTTARAKHAND-263132 INDIA",
    },
    {
        id: 27,
        admnNo: 1133,
        studentName: "KIRAN NEGI",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "LAXMAN SINGH",
        address: "VILL DHUDHALIYA MANRAL, ,ALMORA, UTTARAKHAND-263656 INDIA",
    },
    {
        id: 28,
        admnNo: 1134,
        studentName: "MAMTA ARYA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "LALIT MOHAN ARYA",
        address: "PANDEY GAON BHIMTAL, ,BHIMTAL, UTTARAKHAND- 263136 INDIA",
    },
    {
        id: 29,
        admnNo: 1135,
        studentName: "DEEPSHIKHA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "CHANDRA PRAKASH ARYA",
        address: "SAKET COLONY, RAM NIWAS ,BHIMTAL, UTTARAKHAND-263136 INDIA",
    },
    {
        id: 30,
        admnNo: 1136,
        studentName: "SONDEEP KUMAR",
        className: "B.ED. II YEAR",
        gender: "MALE",
        fatherName: "HARISH PRASAD",
        address:
            "VILL MARAM PO SONASILLING TEH BHANOLI,\n,ALMORA, UTTARAKHAND-263622 INDIA",
    },
    {
        id: 31,
        admnNo: 1137,
        studentName: "ANJALI MEHRA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "JIWAN SINGH MEHRA",
        address:
            "HOUSE NO 34 SIRKOT, VILLAGE PARKOTI ,BAGESHWAR, UTTARAKHAND-263635 INDIA",
    },
    {
        id: 32,
        admnNo: 1138,
        studentName: "VINOD SINGH",
        className: "B.ED. II YEAR",
        gender: "MALE",
        fatherName: "SURENDRA SINGH RAMOLA",
        address:
            "VILL RAMOLAGON OP TUSHRAR BLOCK\nOKHALKANDA, ,NAINITAL, UTTARAKHAND-263157",
    },
    {
        id: 33,
        admnNo: 1139,
        studentName: "AANCHAL VALMIKI",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "SANJAY LAL",
        address:
            "ST. JHOPEH COLLEGE, TALLITAL NAINITAL ,NAINITAL, UTTARAKHAND-263002 INDIA",
    },
    {
        id: 34,
        admnNo: 1140,
        studentName: "DEEPSHIKHA ARYA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "RAKESH CHANDRA",
        address: "ARIES MANORA NAINITAL, ,NAINITAL, UTTARAKHAND-\n263002 INDIA",
    },
    {
        id: 35,
        admnNo: 1141,
        studentName: "SUNITA GOSWAMI",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "RAMESH GIRI",
        address:
            "VILL CHANDIKHET PO GANAI CHAUKHUTIA, ,ALMORA, UTTARAKHAND-263656 INDIA",
    },
    {
        id: 36,
        admnNo: 1142,
        studentName: "HEMA ARYA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "BISHAN RAM",
        address:
            "VILL HARINAGAR PO NATHUWAKHAN, ,NAINITAL,\nUTTARAKHAND-263158 INDIA",
    },
    {
        id: 37,
        admnNo: 1143,
        studentName: "SHAILJA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "TARA SINGH",
        address:
            "BERA TALADA KANDA, SANIODIYAR ,NAINITAL, UTTARAKHAND-263642 INDIA",
    },
    {
        id: 38,
        admnNo: 1144,
        studentName: "RANJANA ARYA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "RAMESH CHANDRA",
        address:
            "VILLAGE SATBUNGA POST SATBUNGA, ,NAINITAL, UTTARAKHAND-263137 INDIA",
    },
    {
        id: 39,
        admnNo: 1145,
        studentName: "RASMI BISHT",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "HEMANT KUMAR",
        address:
            "GAUR DHARA GALI NO. 1 BITHORIA NO. 1 KATHGHARIYA, ,HALDWANI, UTTARAKHAND-263139",
    },
    {
        id: 40,
        admnNo: 1146,
        studentName: "DEEKSHA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "MOHAN PRAKASH\nBUDHLAKOTI",
        address:
            "VILLAGE KHAMARI POST OFFICE NALNI DISTRICT\nNAINITAL, ,NAINITAL, UTTARAKHAND-263001 INDIA",
    },
    {
        id: 41,
        admnNo: 1147,
        studentName: "MAHIMA SHARMA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "ARVIND SHARMA",
        address: "NAINI DEVELOPER RETRET, ,BHOWALI, UTTARAKHAND 263001 INDIA",
    },
    {
        id: 42,
        admnNo: 1148,
        studentName: "DAKSHATA JANTWAL",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "INDER SINGH JANTWAL",
        address:
            "BLOCK ROAD NEAR RAMLILA GROUND MALLITAL,\n,BHIMTAL, UTTARAKHAND-263136 INDIA",
    },
    {
        id: 43,
        admnNo: 1149,
        studentName: "TANISHA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "VIJAY JOSHI",
        address: "TALLITAL BHIMTAL, ,BHIMTAL, UTTARAKHAND-263136 INDIA",
    },
    {
        id: 44,
        admnNo: 1150,
        studentName: "SHIVANI SAH",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "DEVENDRA LAL SAH",
        address:
            "SHITLAPUSHKAR POST DWARAHAT MALLI BAZAR ,\n,DWARAHAT, UTTARAKHAND-263653 INDIA",
    },
    {
        id: 45,
        admnNo: 1151,
        studentName: "ANGEL GOWAN",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "RAVINDRA GOWAN",
        address: "NAGARI GAON FARSOLI, ,NAINITAL, UTTARAKHAND-\n263132 INDIA",
    },
    {
        id: 46,
        admnNo: 1152,
        studentName: "DEEPA ARYA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "DAYAL KUMAR",
        address: "THAKUDA TALLITAL, ,BHIMTAL, UTTARAKHAND- 263136 INDIA",
    },
    {
        id: 47,
        admnNo: 1153,
        studentName: "ARTI BHAGAT",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "PRATAP SINGH BHAGAT",
        address: "BILASPUR PANDEY GAON, ,NAINITAL, UTTARAKHAND- 263136 INDIA",
    },
    {
        id: 48,
        admnNo: 1155,
        studentName: "ANJALI KORANGA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "NANDAN SINGH KORANGA",
        address:
            "NAGARUPAHARI RAWAIKHAL BAGESHWAR,\n,BAGESHWAR, UTTARAKHAND-263642 INDIA",
    },
    {
        id: 49,
        admnNo: 1156,
        studentName: "RICHA RAWAT",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "I. S. RAWAT",
        address:
            "ALMA COTTAGE NO. 7 MALLITAL NAINITAL,\n,NAINITAL, UTTARAKHAND-263002 INDIA",
    },
    {
        id: 50,
        admnNo: 1157,
        studentName: "SUMAN",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "RAJENDRA PRASAD",
        address:
            "GRAM MOHNAGAW POST NARTOLA BLOCK\nOKHALKANDA, ,NAINITAL, UTTARAKHAND-263132",
    },
    {
        id: 51,
        admnNo: 1158,
        studentName: "CHITRA NEGI",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "NARENDRA SINGH NEGI",
        address:
            "HIGH COURT CAMPUS CANTON LODGE, ,NAINITAL, UTTARAKHAND-263001 INDIA",
    },
    {
        id: 52,
        admnNo: 1160,
        studentName: "PRIYANKA BISHT",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "RATAN SINGH BISHT",
        address: "T.B. SANITORIUM BHOWALI, ,BHOWALI, UTTARAKHAND-263132 INDIA",
    },
    {
        id: 53,
        admnNo: 1162,
        studentName: "DEEKSHA PATHAK",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "GOKULANAND\nPATHAK",
        address:
            "DASHAULI PITHORAGARH, ,PITHORAGARH,\nUTTARAKHAND-252533 INDIA",
    },
    {
        id: 54,
        admnNo: 1163,
        studentName: "PRIYANKA PALARIYA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "JAGDISH CHANDRA",
        address: "ALCHOUNA CHANFI, ,BHIMTAL, UTTARAKHAND-263136 INDIA",
    },
    {
        id: 55,
        admnNo: 1164,
        studentName: "PANKAJ KUMAR\nARYA",
        className: "B.ED. II YEAR",
        gender: "MALE",
        fatherName: "SURESH CHANDRA\nARYA",
        address:
            "SAINIK SCHOOL GHORAKHAL , ,BHOWALI,\nUTTARAKHAND-263156 INDIA",
    },
    {
        id: 56,
        admnNo: 1166,
        studentName: "BHUPENDRA SINGH BISHT",
        className: "B.ED. II YEAR",
        gender: "MALE",
        fatherName: "NARAYAN SINGH BISHT",
        address:
            "SPRING DALE COMPOUND TALLITAL, ,NAINITAL, UTTARAKHAND-263002 INDIA",
    },
    {
        id: 57,
        admnNo: 1167,
        studentName: "NIDHI TADHIYAL",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "BHUPENDRA SINGH",
        address: "BHATKOTE CHAUKHUTIA, ,ALMORA, UTTARAKHAND- 263656 INDIA",
    },
    {
        id: 58,
        admnNo: 1168,
        studentName: "GARIMA RAUTELA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "ADITYA KUMAR",
        address:
            "TYPE II BSNL COMPOUND MALLITAL NAINITAL ,\n,NAINITAL, UTTARAKHAND- INDIA",
    },
    {
        id: 59,
        admnNo: 1169,
        studentName: "PRIYANSHU BHOJ",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "RAJESH KUMAR",
        address:
            "VILL. RAILAPALI NEAR ROADWAYS WORKSHOP, PWD\nCOLONY ,ALMORA, UTTARAKHAND-263601 INDIA",
    },
    {
        id: 60,
        admnNo: 1170,
        studentName: "DEVENDRA MEHTA",
        className: "B.ED. II YEAR",
        gender: "MALE",
        fatherName: "GOPAL SINGH MEHTA",
        address: "GODI MUNIYACHAUR POST DAULA, ,ALMORA, UTTARAKHAND- INDIA",
    },
    {
        id: 61,
        admnNo: 1171,
        studentName: "JAYESH KUMAR",
        className: "B.ED. II YEAR",
        gender: "MALE",
        fatherName: "RAMESH CHANDRA",
        address:
            "SPRJNG DALE COMPOUND PWD , NEAR GGIC SCHOOL\nTALLITAL NAINITAL ,NAINITAL, UTTARAKHAND-263002",
    },
    {
        id: 62,
        admnNo: 1172,
        studentName: "MAMTA BOHRA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "GANGA SINGH BOHRA",
        address:
            "CHURHA PITA, CHURHA MEHTA REETHA SAHIB ,\n,CHAMPAWAT, UTTARAKHAND- INDIA",
    },
    {
        id: 63,
        admnNo: 1173,
        studentName: "POOJA KULYAL",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "LAL SINGH KULYAL",
        address: "JANGALIYA GAON BHIMTAL, ,NAINITAL,\nUTTARAKHAND-263136 INDIA",
    },
    {
        id: 64,
        admnNo: 1174,
        studentName: "NEHA NAYAL",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "DUNGAR SINGH\nNAYAL",
        address:
            "NAYAL GARMENTS MALLITAL , ,BHIMTAL,\nUTTARAKHAND-263136 INDIA",
    },
    {
        id: 65,
        admnNo: 1175,
        studentName: "VANDANA NATH",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "RAVINDRA NATH",
        address: "CANTT ROAD JAKHANI, ,PITHORAGARH, UTTARAKHAND-262501 INDIA",
    },
    {
        id: 66,
        admnNo: 1176,
        studentName: "KRITIKA JOSHI",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "BHUWAN CHANDRA JOSHI",
        address:
            "HOUSE NO 182 SARGAKHET, ,MUKTESHWAR, UTTARAKHAND-263137 INDIA",
    },
    {
        id: 67,
        admnNo: 1177,
        studentName: "SHEELA PARIHAR",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "BHUPAL SINGH PARIHAR",
        address: "TEHSIL ROAD BAGESHWAR, ,BAGESHWAR, UTTARAKHAND- INDIA",
    },
    {
        id: 68,
        admnNo: 1178,
        studentName: "SHIKHA PATHAK",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "PRAKASH PATHAK",
        address: "BLOCK ROARD BHIMTAL , ,BHIMTAL, UTTARAKHAND-\n263136 INDIA",
    },
    {
        id: 69,
        admnNo: 1179,
        studentName: "RASHMI BISHT",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "PURAN SINGH BISHT",
        address: "VILLAGE , ,BHOWALI, UTTARAKHAND-263132 INDIA",
    },
    {
        id: 70,
        admnNo: 1180,
        studentName: "HEENA MANRAL",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "DIGPAL SINGH MANRAL",
        address:
            "VILLAGE UDEPUR BLOCK SYALDE , ,ALMORA, UTTARAKHAND-263661 INDIA",
    },
    {
        id: 71,
        admnNo: 1182,
        studentName: "NEHA GAIRA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "RAJENDRA SINGH GAIRA",
        address: "RUKUT COTTAGE MALLITAL, ,NAINITAL, UTTARAKHAND-263002 INDIA",
    },
    {
        id: 72,
        admnNo: 1183,
        studentName: "ANKITA BHANDARI",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "D. S. BHANDARI",
        address:
            "PRARY LODGE AYARPATA MALLITAL, ,NAINITAL,\nUTTARAKHAND-263001 INDIA",
    },
    {
        id: 73,
        admnNo: 1184,
        studentName: "VIPASHA ADHIKARI",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "PRAKASH SINGH ADHIKARI",
        address: "KOTILA ALMORA, ,ALMORA, UTTARAKHAND-263653 INDIA",
    },
    {
        id: 74,
        admnNo: 1185,
        studentName: "AKANSHA KULYAL",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "DHERMENDAR SINGH\nKULYAL",
        address: "JANGALIYA GAON , ,BHIMTAL, UTTARAKHAND-263136\nINDIA",
    },
    {
        id: 75,
        admnNo: 1186,
        studentName: "RUCHI CHAUNAL",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "MADAN PANDEY",
        address:
            "VILLAGE GHINGHRANI PO CHANFI, ,BHIMTAL, UTTARAKHAND-263136 INDIA",
    },
    {
        id: 76,
        admnNo: 1187,
        studentName: "KARAN KUMAR",
        className: "B.ED. II YEAR",
        gender: "MALE",
        fatherName: "MANGAL RAM",
        address:
            "ST. JOSEPHS COLLEGE TALLITAL NAINITAL ,\n,NAINITAL, UTTARAKHAND-263002 INDIA",
    },
    {
        id: 77,
        admnNo: 1188,
        studentName: "MAMTA ARYA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "MADAN LAL",
        address: "HARINAGAR HARTOLA, ,NAINITAL, UTTARAKHAND- 263158 INDIA",
    },
    {
        id: 78,
        admnNo: 1189,
        studentName: "MEENU MEHTA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "HARGOVIND SINGH\nMEHTA",
        address: "BHAGTURA MEHRAGAON , ,BHIMTAL, UTTARAKHAND-\n263132 INDIA",
    },
    {
        id: 79,
        admnNo: 1190,
        studentName: "HEMLATA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "FAKIR RAM",
        address: "JUNAYAL BAGESHWAR, ,BAGESHWAR, UTTARAKHAND- 263634 INDIA",
    },
    {
        id: 80,
        admnNo: 1191,
        studentName: "DISHA PADHALNI",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "JEEVAN CHANDRA PADHALNI",
        address: "MEHRAGAON , ,BHOWALI, UTTARAKHAND-263132 INDIA",
    },
    {
        id: 81,
        admnNo: 1192,
        studentName: "PRENA JOSHI",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "DEEP CHANDRA JOSHI",
        address:
            "HGS STAFF QUATER SOS COMPLEX , ,BHIMTAL, UTTARAKHAND-263132 INDIA",
    },
    {
        id: 82,
        admnNo: 1193,
        studentName: "PRATIBHA",
        className: "B.ED. II YEAR",
        gender: "FEMALE",
        fatherName: "HARISH CHANDRA",
        address:
            "HERMANN GMEINER SCHOOL COMPOUND , ,BHIMTAL,\nUTTARAKHAND-263136 INDIA",
    },
    {
        id: 83,
        admnNo: 1194,
        studentName: "MICHELLE SAMUEL",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "ANIL SAMUEL",
        address:
            "ST. FRANCIS COTTAGE NEAR RAJ BHAWAN TALLITAL,\n,NAINITAL, UTTARAKHAND-263001 INDIA",
    },
    {
        id: 84,
        admnNo: 1195,
        studentName: "PRIYANKA CHARAN",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "ANIL CHARAN",
        address:
            "T. B. SAINITORIUM BHOWALI, ,BHOWALI, UTTARAKHAND-263132 INDIA",
    },
    {
        id: 85,
        admnNo: 1196,
        studentName: "ARPAN BISWAKARMA",
        className: "B.ED. I YEAR",
        gender: "MALE",
        fatherName: "CHANDRA LALL BISWAKARMA",
        address: "HEE BAZZAR WEST SIKKIM, ,SIKKIM, SIKKIM-737113 INDIA",
    },
    {
        id: 86,
        admnNo: 1197,
        studentName: "NANDINI PANDEY",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "L M PANDEY",
        address:
            "NEAR CHIRPING HILLS KHUTANI , ,BHIMTAL, UTTARAKHAND-263136 INDIA",
    },
    {
        id: 87,
        admnNo: 1198,
        studentName: "CHANDRA BALLABH",
        className: "B.ED. I YEAR",
        gender: "MALE",
        fatherName: "DEV RAM ARYA",
        address: "HOUSE NO 290, JANESTATE ,BHIMTAL, UTTARAKHAND-\n263136 INDIA",
    },
    {
        id: 88,
        admnNo: 1199,
        studentName: "KAVITA BHATT",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "HARISH CHANDRA BHATT",
        address: "TB SAINITORIUM , ,BHOWALI, UTTARAKHAND-263132 INDIA",
    },
    {
        id: 89,
        admnNo: 1200,
        studentName: "BHAWANA PALARIYA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "RAJENDRA KUMAR PALARIYA",
        address: "SOUN GAON BHIMTAL , ,BHIMTAL, UTTARAKHAND- INDIA",
    },
    {
        id: 90,
        admnNo: 1201,
        studentName: "KM BHAWANA RANA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "BISHAN SINGH RANA",
        address: "ALMORA, ,ALMORA, UTTARAKHAND- INDIA",
    },
    {
        id: 91,
        admnNo: 1202,
        studentName: "NISHI TAMTA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "GOPAL RAM TAMTA",
        address:
            "SEED STORE RAMLILA GROUND BHOWALI, ,BHOWALI,\nUTTARAKHAND- INDIA",
    },
    {
        id: 92,
        admnNo: 1203,
        studentName: "PRAKHAR RATHOUR",
        className: "B.ED. I YEAR",
        gender: "MALE",
        fatherName: "SANJAY SINGH RATHOUR",
        address:
            "A-228 AWAS VIKAS COLONY BUDAUN , ,BUDAUN, UTTRA PRADESH-243601 INDIA",
    },
    {
        id: 93,
        admnNo: 1204,
        studentName: "DEEPTI PADIYAR",
        className: "B.ED. I YEAR",
        gender: "MALE",
        fatherName: "DINESH SINGH\nPADIYAR",
        address: "JANGLIYA GAUN , ,BHIMTAL, UTTARAKHAND-263136\nINDIA",
    },
    {
        id: 94,
        admnNo: 1205,
        studentName: "MAHAK",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "DINESH SINGH PADIYAR",
        address: "JANGLIYA GAON , ,BHIMTAL, UTTARAKHAND-263136 INDIA",
    },
    {
        id: 95,
        admnNo: 1206,
        studentName: "KAVITA BISHT",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "MADHO SINGH BISHT",
        address:
            "VILLAGE SURI PO RAYURA JAINTI, ,ALMORA, UTTARAKHAND-263626 INDIA",
    },
    {
        id: 96,
        admnNo: 1207,
        studentName: "NEHA RANI",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "PRAKASH CHANDRA",
        address:
            "ALCHONA PANDEY CHHOR CHANFI BHIMTAL,\n,BHIMTAL, UTTARAKHAND-263136 INDIA",
    },
    {
        id: 97,
        admnNo: 1208,
        studentName: "PRIYANKA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "RAVINDRA KUMAR",
        address:
            "NARAYAN NAGAR MALLITAL NAINITAL , ,NAINITAL,\nUTTARAKHAND-263001 INDIA",
    },
    {
        id: 98,
        admnNo: 1209,
        studentName: "VAISHALI SIRALA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "KAILASH CHNADRA SIRALA",
        address:
            "GALWAY HOUSE AYARPATTA MALLITAL NAINITAL ,\n,NAINITAL, UTTARAKHAND-263001 INDIA",
    },
    {
        id: 99,
        admnNo: 1210,
        studentName: "NEHA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "MOHAN CHANDRA",
        address:
            "VILL- DARMOLI PO MUKTESHWAR , ,MUKTESHWAR, UTTARAKHAND-263138 INDIA",
    },
    {
        id: 100,
        admnNo: 1211,
        studentName: "KULDEEP SINGH KURIA",
        className: "B.ED. I YEAR",
        gender: "MALE",
        fatherName: "BATCHI SINGH KURIA",
        address:
            "VILL- GETHIYA PO GETHIYA SENITORIUM , ,NAINITAL, UTTARAKHAND-263127 INDIA",
    },
    {
        id: 101,
        admnNo: 1212,
        studentName: "HIMANI ARYA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "HARISH RAM ARYA",
        address:
            "C3 2ND FLOOR SUMMER RESIDENCY BHOWALI,\n,BHOWALI, UTTARAKHAND-263132 INDIA",
    },
    {
        id: 102,
        admnNo: 1213,
        studentName: "KAMAKSHI GOEL",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "AJAY KUMAR GOEL",
        address:
            "SHIVLARPUR PANDEY KRISHNA KUNJ COLONY\nKASHIPUR ROAD RAMNAGAR, ,RAMNAGAR,",
    },
    {
        id: 103,
        admnNo: 1214,
        studentName: "SANJAY KUMAR",
        className: "B.ED. I YEAR",
        gender: "MALE",
        fatherName: "LACHAM RAM ARYA",
        address:
            "STAFF HOUSE NO 7 MALLITAL , ,NAINITAL, UTTARAKHAND-263001 INDIA",
    },
    {
        id: 104,
        admnNo: 1215,
        studentName: "POONAM SUYAL",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "DEVI DATT SUYAL",
        address:
            "POPULAR HOUSE COMPOUND MALLITAL , ,NAINITAL, UTTARAKHAND-263001 INDIA",
    },
    {
        id: 105,
        admnNo: 1216,
        studentName: "LATA MISHRA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "RAJENDRA MISHRA",
        address: "MALLITAL BHIMTAL, ,BHIMTAL, UTTARAKHAND- 263136 INDIA",
    },
    {
        id: 106,
        admnNo: 1217,
        studentName: "POONAM BHATT",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "MOHAN CHANDRA\nBHATT",
        address:
            "VIL. SHIMAYAL POST DIYARI , ,NAINITAL,\nUTTARAKHAND-263138 INDIA",
    },
    {
        id: 107,
        admnNo: 1218,
        studentName: "PRIYANKA PATHAK",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "GANESH DUTT PATHAK",
        address:
            "ATARIA MANDIR ROAD JAGATPURA RUDRAPUR ,\n,RUDRAPUR, UTTARAKHAND-263153 INDIA",
    },
    {
        id: 108,
        admnNo: 1219,
        studentName: "DEEPIKA NEGI",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "SHER SINGH NEGI",
        address:
            "20TH GREEN NEAR MAHARISHI VIDYA MANDIR\nSCHOOL TIRCHAKHET BHOWALI, ,BHOWALI,",
    },
    {
        id: 109,
        admnNo: 1220,
        studentName: "PREETI ARYA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "KANHAIYA LAL ARYA",
        address:
            "NEW BASTI SUKHATAL MALLITAL, ,NAINITAL, UTTARAKHAND-263001 INDIA",
    },
    {
        id: 110,
        admnNo: 1221,
        studentName: "VANDANA BISHT",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "GOPAL SINGH",
        address: "VILLAGE KAUL DHARI , ,NAINITAL, UTTARAKHAND-\nINDIA",
    },
    {
        id: 111,
        admnNo: 1222,
        studentName: "GANGA KUNWAR",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "DAN SINGH KUNWAR",
        address:
            "VILL.JIMTAD GALATI POST FULTARI, ,PITHORAGARH, UTTARAKHAND-262576 INDIA",
    },
    {
        id: 112,
        admnNo: 1223,
        studentName: "ASHISH RAJ",
        className: "B.ED. I YEAR",
        gender: "MALE",
        fatherName: "HARINDER SINGH",
        address:
            "H.NO. 134A STREET NO.-6 VIPIN GARDEN EXT. , ,DELHI,\nDELHI-110059 INDIA",
    },
    {
        id: 113,
        admnNo: 1224,
        studentName: "LALITA JOSHI",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "CHANDRA SHEKHAR JOSHI",
        address:
            "VILL. BARAKOT POST OFF. RAJURA DIST ALMORA,\n,ALMORA, UTTARAKHAND-263626 INDIA",
    },
    {
        id: 114,
        admnNo: 1225,
        studentName: "ASHOK KUMAR",
        className: "B.ED. I YEAR",
        gender: "MALE",
        fatherName: "BHUWAN CHANDRA",
        address:
            "VILL MALLA GAIRAR P/O JHANKARSAIM, ,ALMORA, UTTARAKHAND-263623 INDIA",
    },
    {
        id: 115,
        admnNo: 1226,
        studentName: "SHEETAL BALMIKI",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "VINOD KUMAR",
        address:
            "LONG VIEW COTTAGE TALLITAL NAINITAL, ,NAINITAL, UTTARAKHAND-263001 INDIA",
    },
    {
        id: 116,
        admnNo: 1227,
        studentName: "UMA DEVALI",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "HEERA SINGH",
        address:
            "SAROJ NIWAS PRATAP PURAM LAL DATH BITHORIYA,\n,HALDWANI, UTTARAKHAND-263139 INDIA",
    },
    {
        id: 117,
        admnNo: 1228,
        studentName: "ARUN DASS",
        className: "B.ED. I YEAR",
        gender: "MALE",
        fatherName: "GOPAL DASS",
        address:
            "POOLED HOUSING COLONY, PINES, ,NAINITAL, UTTARAKHAND-263001 INDIA",
    },
    {
        id: 118,
        admnNo: 1229,
        studentName: "KM JASAUDA DEVI",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "ANANDRAM",
        address:
            "VILL. LEDHARA POST PO HALDYANI, ,NAINITAL, UTTARAKHAND-263134 INDIA",
    },
    {
        id: 119,
        admnNo: 1230,
        studentName: "KOMAL LATWAL",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "SHER SINGH LATWAL",
        address: "CHASALI ALMORA, ,ALMORA, UTTARAKHAND-263601 INDIA",
    },
    {
        id: 120,
        admnNo: 1231,
        studentName: "PRIYANSHI ADHIKARI",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "GOVIND SINGH\nADHIKARI",
        address:
            "SAINIK SCHOOL GHORAKHAL , ,BHOWALI,\nUTTARAKHAND-263156 INDIA",
    },
    {
        id: 121,
        admnNo: 1232,
        studentName: "NIKHIL CHANDRA\nUPADHYAY",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "MOHAN CHNADRA\nUPADHYAY",
        address:
            "VILL. JAKH PO RATIGHAT , ,NAINITAL, UTTARAKHAND-\n263135 INDIA",
    },
    {
        id: 122,
        admnNo: 1233,
        studentName: "PRIYANKA RANI",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "SHANTI RAM",
        address:
            "ASHDLE COMPOUND SUKHATAL MALLITAL,\n,NAINITAL, UTTARAKHAND-263001 INDIA",
    },
    {
        id: 123,
        admnNo: 1234,
        studentName: "MEENAKSHI BHATT",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "REBADHAR BHATT",
        address:
            "SANITORIUM SIRODI BHOWALI KOSYA KUTUAULI,\n,BHOWALI, UTTARAKHAND-263156 INDIA",
    },
    {
        id: 124,
        admnNo: 1235,
        studentName: "SEEMA ARYA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "PRAKASH CHANDRA",
        address:
            "VILLAGE LOD POST SUPI NAINITAL, ,NAINITAL, UTTARAKHAND-263132 INDIA",
    },
    {
        id: 125,
        admnNo: 1236,
        studentName: "DIVYA KORANGA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "KESHAR SINGH\nKORANGA",
        address: "SAMA BAGESHWAR , ,BAGESHWAR, UTTARAKHAND-\nNA INDIA",
    },
    {
        id: 126,
        admnNo: 1237,
        studentName: "RUBEENA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "ABDUL HABEEB",
        address:
            "VILL. MUJHOLI GELEKH P/O GOLUCHINA, ,ALMORA, UTTARAKHAND-263655 INDIA",
    },
    {
        id: 127,
        admnNo: 1238,
        studentName: "ANCHAL UPADHYAY",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "YOGESH CHANDRA UPADHYAY",
        address:
            "M.NO 7 SHIVPURAM MUKHANI , ,HALDWANI, UTTARAKHAND-263139 INDIA",
    },
    {
        id: 128,
        admnNo: 1239,
        studentName: "KHASHTI BISHT",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "MOHAN SINGH",
        address: "DHANACHULI DHARI, ,BHIMTAL, UTTARAKHAND- 263132 INDIA",
    },
    {
        id: 129,
        admnNo: 1240,
        studentName: "KAVITA ARYA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "BHAWANI RAM ARYA",
        address:
            "MOHAN LAL SAH BAL VM MALLITAL NAINITAL STAFF\nHOUSE ROOM , ,NAINITAL, UTTARAKHAND-263002",
    },
    {
        id: 130,
        admnNo: 1241,
        studentName: "DEEPSHIKHA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "JEEVAN CHANDRA ARYA",
        address:
            "SAKET COLONY WARD NO 2 BHIMTAL , ,BHIMTAL, UTTARAKHAND-263136 INDIA",
    },
    {
        id: 131,
        admnNo: 1242,
        studentName: "SANGEETA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "PRATAP RAM",
        address:
            "HIGHT COURT COMPOUND MALLITAL NAINITAL ,\n,NAINITAL, UTTARAKHAND-263002 INDIA",
    },
    {
        id: 132,
        admnNo: 1243,
        studentName: "KM DEEPA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "NAVEEN CHANDRA",
        address:
            "VILL. JHALORI PO KHER ALMORA, ,ALMORA, UTTARAKHAND-263645 INDIA",
    },
    {
        id: 133,
        admnNo: 1244,
        studentName: "BHARTI ARYA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "JEEVAN LAL",
        address: "VILLAGE MALLA NIGLAT, ,BHOWALI, UTTARAKHAND- INDIA",
    },
    {
        id: 134,
        admnNo: 1245,
        studentName: "NEHA RAWAT",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "DHAN SINGH RAWAT",
        address: "VILL. BHAURA, ,ALMORA, UTTARAKHAND-263653 INDIA",
    },
    {
        id: 135,
        admnNo: 1246,
        studentName: "PARVESH MEHRA",
        className: "B.ED. I YEAR",
        gender: "MALE",
        fatherName: "BHARAT SINGH\nMEHRA",
        address:
            "TANGSATAN HALL COMPOUND TALLITAL , ,NAINITAL,\nUTTARAKHAND-263002 INDIA",
    },
    {
        id: 136,
        admnNo: 1247,
        studentName: "MONIKA ARYA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "DAYAL RAM",
        address: "VILL. BHAKUNKHOLA, ,BAGESHWAR, UTTARAKHAND- 263641 INDIA",
    },
    {
        id: 137,
        admnNo: 1248,
        studentName: "LAKSHITA SAH",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "KAILASH CHANDRA SAH",
        address: "ALMORA, DAWARAHAT ,DWARAHAT, UTTARAKHAND- 263653 INDIA",
    },
    {
        id: 138,
        admnNo: 1249,
        studentName: "BABEETA JOSHI",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "GIRISH CHANDRA JOSHI",
        address: "MALYAL GAON KULSEBI , ,RANIKHET, UTTARAKHAND- 263652 INDIA",
    },
    {
        id: 139,
        admnNo: 1250,
        studentName: "GUNJAN KHATRI",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "MAHIPAL SINGH",
        address:
            "VILL. BHATKOTE CHAUKHUTIYA , ,ALMORA,\nUTTARAKHAND-263656 INDIA",
    },
    {
        id: 140,
        admnNo: 1251,
        studentName: "SUSHIL KUMAR\nDANAI",
        className: "B.ED. I YEAR",
        gender: "MALE",
        fatherName: "GIRISH CHANDRA\nDANAI",
        address: "HARIYA GAOUN BHIMTAL , ,BHIMTAL, UTTARAKHAND-\n263136 INDIA",
    },
    {
        id: 141,
        admnNo: 1252,
        studentName: "DIVYA BELWAL",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "DAYAL CHANDRA BELWAL",
        address: "VILL. SALARI AMRITPUR , ,BHIMTAL, UTTARAKHAND- 263136 INDIA",
    },
    {
        id: 142,
        admnNo: 1253,
        studentName: "GUNJAN ARYA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "NARESH KUMAR ARYA",
        address: "SHYAMKHET , ,BHOWALI, UTTARAKHAND-263132 INDIA",
    },
    {
        id: 143,
        admnNo: 1254,
        studentName: "KIRAN RAWAT",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "AVATAR SINGH RAWAT",
        address: "BSNL OFFICE BLOCK ROAD , ,BHIMTAL, UTTARAKHAND-263136 INDIA",
    },
    {
        id: 144,
        admnNo: 1255,
        studentName: "SHIKHA BHATT",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "BHAIRAB DATT BHATT",
        address:
            "BD BHATT GENERAL STORE TALLITAL , ,BHIMTAL,\nUTTARAKHAND-263136 INDIA",
    },
    {
        id: 145,
        admnNo: 1256,
        studentName: "PRIYANKA JOSHI",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "ISHWARI DATT JOSHI",
        address: "BSNL OFFICE BHIMTAL , ,BHIMTAL, UTTARAKHAND- 263136 INDIA",
    },
    {
        id: 146,
        admnNo: 1257,
        studentName: "HIMADRI PARGAIN",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "SURESH PARGAIN",
        address:
            "HARIPUR MEHTOLIA CHORGALIYA HALDWANI ,\n,HALDWANI, UTTARAKHAND-263139 INDIA",
    },
    {
        id: 147,
        admnNo: 1258,
        studentName: "BHUWAN PRASAD",
        className: "B.ED. I YEAR",
        gender: "MALE",
        fatherName: "KHEEM RAM",
        address: "VILL. JHALANDUNGRA ALMORA, ,ALMORA, UTTARAKHAND-263626 INDIA",
    },
    {
        id: 148,
        admnNo: 1259,
        studentName: "GAYTRI GARIYA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "KHUSHAL SINGH\nGARIYA",
        address:
            "H NO 24 VILL. MEHRAGAON , ,BHIMTAL,\nUTTARAKHAND-263132 INDIA",
    },
    {
        id: 149,
        admnNo: 1260,
        studentName: "HIMANI POKHARIYA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "PURAN SINGH POKHARIYA",
        address:
            "VILL.DUTI BAGAD PO. JAULJIBI , ,PITHORAGARH, UTTARAKHAND- INDIA",
    },
    {
        id: 150,
        admnNo: 1261,
        studentName: "SANJANA BISHT",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "DAN SINGH BISHT",
        address: "DUGAI ESTATE , ,BHOWALI, UTTARAKHAND-263132\nINDIA",
    },
    {
        id: 151,
        admnNo: 1262,
        studentName: "VINITA ARYA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "RANJEET RAM",
        address:
            "KAILAKHAN PARVO CANT TALLITAL , ,NAINITAL, UTTARAKHAND-263002 INDIA",
    },
    {
        id: 152,
        admnNo: 1263,
        studentName: "SAKSHEE ARYA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "CHANDAN LAL",
        address:
            "LADHOLI KOTA PO SUWAKHAN BHANOLI , ,ALMORA, UTTARAKHAND-263623 INDIA",
    },
    {
        id: 153,
        admnNo: 1264,
        studentName: "GEETIKA PALARIYA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "RAMESH CHANDRA PALARIYA",
        address:
            "VILL. SALARI PO AMRITPUR , ,BHIMTAL, UTTARAKHAND-263136 INDIA",
    },
    {
        id: 154,
        admnNo: 1265,
        studentName: "POONAM ARYA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "MOHAN CHANDRA",
        address: "BHARATPUR , ,BHIMTAL, UTTARAKHAND-263136 INDIA",
    },
    {
        id: 155,
        admnNo: 1266,
        studentName: "KALPANA JOSHI",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "B R JOSHI",
        address: "RAHAR WARD NO 3, ,BHOWALI, UTTARAKHAND- INDIA",
    },
    {
        id: 156,
        admnNo: 1267,
        studentName: "BHARTI SATI",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "RAJENDRA PRASAD SATI",
        address: "HARTAPA RATIGHAT , ,NAINITAL, UTTARAKHAND- 263135 INDIA",
    },
    {
        id: 157,
        admnNo: 1268,
        studentName: "MONIKA KANYAL",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "DIWAN SINGH KANYAL",
        address: "MANDALSHERA BAGESHWAR, ,BAGESHWAR, UTTARAKHAND-263642 INDIA",
    },
    {
        id: 158,
        admnNo: 1269,
        studentName: "RAVI SAH",
        className: "B.ED. I YEAR",
        gender: "MALE",
        fatherName: "HARENDRA SAH",
        address:
            "PRIYANKA JWELLERS MAIN MARKET , ,BHOWALI,\nUTTARAKHAND-263132 INDIA",
    },
    {
        id: 159,
        admnNo: 1270,
        studentName: "REKHA ARYA",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "BASANT LAL",
        address: "VILL. LVAISHAL BHOWALI, ,BHOWALI, UTTARAKHAND-\n263132 INDIA",
    },
    {
        id: 160,
        admnNo: 1271,
        studentName: "HIMANSHU PATHAK",
        className: "B.ED. I YEAR",
        gender: "MALE",
        fatherName: "PRAKASH CHANDRA PATHAK",
        address:
            "11 QUERALY POST FALYATI TAH SHISHAL\nBHAGESHWAR, ,RANIKHET, UTTARAKHAND-263645",
    },
    {
        id: 161,
        admnNo: 1272,
        studentName: "POOJA PANDAY",
        className: "B.ED. I YEAR",
        gender: "FEMALE",
        fatherName: "MOHAN CHANDRA PANDEY",
        address:
            "VILL. BOHRAGAUN WARD NO 4 , ,BHIMTAL, UTTARAKHAND-263136 INDIA",
    },
];

